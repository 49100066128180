var stickyHeader = true;
// var transformHomepageDonationAmountImages = false;
var flyoutNav = true;
// var fullscreenNav = false;
// var homeQuickGivingPreFooter = false;
var countUpStats = true;
var countUpStatsDuration = 2500;
// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video
var feedTitleLinks = ['/Pages/News/', '/Pages/Events/', '/our-stories'];


// Centered logo
$(".headerContent a.mainLogo").insertBefore(".headerContent ul.socialIcons");


// Header search
$(".headerContent ul.socialIcons .search").on("click", function() {
  var headerSearch = $(".headerContent .header-search");
    $(this).toggleClass("active");
    headerSearch.toggleClass("active");
});


// Run function on resize
$(document).ready(function () {
  headerSocialWidth();
  $(window).resize(function() {
      headerSocialWidth();
  });
});


// Header - Centered logo
function headerSocialWidth() {
  var headerButtonsWidth = $(".headerContent .mainCallToActionButtons").outerWidth(true);
  var burgerButtonWidth = $(".headerContent .menuMainAlt").outerWidth(true);
  var searchButtonWidth = $(".headerContent ul.socialIcons .search").width();
  $(".headerContent ul.socialIcons .search").css("margin-right", headerButtonsWidth - burgerButtonWidth - searchButtonWidth + "px");
}


// Homepage snippet feed - Spacing
if ($(".homeFeed.homeFeedsnippets").length) {
  $(".homeFeedsWrapper ").css("padding-bottom", "0");
}


// Slick slider
$(".homeFeed.homeFeedsnippets").find(".feedList").slick({
  slidesToShow: 1,
  centerMode: false,
  slidesToScroll: 1,
  arrows: false,
  dots: true,
  pauseOnHover: true,
  pauseOnDotsHover: true,
  autoplay: true,
  autoplaySpeed: 6000
});


// Card - No banner
$(".homefeaturecategory-homeboximpactstats td:has(img)").addClass("containsIcon");


// Homepage video
$(document).ready(function () {
  $(".homefeaturecategory-homepage-video").each(function() {
    $(this).find('> *:not(iframe)').wrapAll('<div class="homeFeatureDetailsWrapper" />');
    $(this).find('iframe').wrapAll('<div class="video-iframe-image-link" />');
  });
});


// Different logo if Scotland
$.get("https://freegeoip.app/json/", function (response) {
    $("#ip").html("IP: " + response.ip);
    $("#country_code").html(response.country_code);
    if(response.region_name == 'Scotland') {
    	$('.mainLogo').addClass("mainLogoScotland");
	}
}, "jsonp");


// Donation form stuff
$('body.donate.become-a-friend  #Regular').click();
$('body.donate[class*="one-off-donation"] #Single').click();


//  GiftAid text
$('.formSecondStep.giftAidStatement label[for="giftAid"]').text('Yes, I want The Anne Frank Trust UK to treat all donations I have made in the last four years and all future donations that I make from the date of this declaration, as Gift Aid donations. I am a UK tax payer and understand that if I pay less income tax and/or capital gains tax than the amount of Gift Aid claimed on all my donations in that tax year it is my responsibility to pay any difference.')